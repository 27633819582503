html {
  scroll-behavior: smooth;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  overflow: hidden;
  line-height: 25px;
}

hr {
  border: 0;
  clear:both;
  display:block;
  width: 96%;
  background-color:#ffffff;
  height: 1px;
}

.container {
  background-color: rgb(96, 172, 179); /* For browsers that do not support gradients */
  background-image: linear-gradient(rgb(96, 172, 179), rgb(196, 230, 233));
  position: fixed;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 8vh 92vh;
  grid-gap: 0;
  grid-template-areas:
       "sidebar main"
       "sidebar main";
}

.container > div.main {
  overflow-y: auto;
  scroll-behavior: smooth;
}

.sidebar {
  grid-area: sidebar;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidebar a {
  display: block;
  color: gray;
  text-decoration: none;
  padding: 8px;
  margin: 2px;
  font-size: 20px;
}

.sidebar a.active {
  color: rgb(44, 159, 215);
  border: solid 0.50px rgb(44, 159, 215);
}

.sidebar a:hover:not(.active) {
  color: white;
  border: solid 0.50px white;
}

.main {
  grid-area: main;
}

@media screen and (max-width: 700px) {
  .sidebar {
    flex-direction: row;
  }

  .container {
    grid-template-areas:
         "sidebar sidebar"
         "main main";
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}

section{
  color:rgb(85, 85, 85);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  /* New Style  */
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 8px;
  box-shadow: 20px 13px 20px black;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Specific Sections Syle */
/* Welcome */
.welcome {
  color: white;
  font-size: calc(30px + 2vmin);
  line-height: normal;
  text-shadow: 13px 11px 20px black;
  color: white;
  justify-content: center;
}

.name-color{
  color: rgb(46, 53, 55);
}

.next-button {
  margin: 10px 6px;
  padding: 20px;
  animation: bounce 0.75s ease-in-out infinite;
   -webkit-animation: bounce 0.75s ease-in-out infinite;
}

@keyframes bounce {
     0%   { transform: translateY(0); }
     50%  { transform: translateY(-30px); }
     100% { transform: translateY(0); }
 }

/*  About */
.about {
  color:rgb(85, 85, 85);
}

.profile-image {
  margin: 5px;
  height: inherit;
  width: inherit;
  border: solid 5px white;
  border-radius: 20px;
}

@media screen and (max-width: 700px) {

  .about {
    line-height: 15px;
  }

  .traits div {
    min-width: 40vw
  }
}

.title {
  font-size: calc(20px + 1vmin);
  margin: 10px;
  border-bottom: solid 0.5px white;
  border-radius: 10px;
}

.traits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

.traits span {
  font-size: calc(20px + 1vmin);
}

.traits > div {
  border: solid 1px white ;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 20vw;
  margin: 5px;
  padding: 5px;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.image-container{
  width: calc(40vw * 40vh);
  height: 35vh;
}

.bio-content {
  width: 50vw;
  margin: 20px 0 20px 0;
  padding: 5px;
  border: solid 1px white ;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: calc(10px + 1vmin);
}

/* Skills */

.skills {
  align-items: center;
  text-align: center;

  color:rgb(85, 85, 85);
}

.skills-title {
  grid-area: skills-title;
}

.detailed {
  grid-area: detailed;
  display: flex;
  justify-content: center;
}

.detailed > div {
  width:80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-content: flex-start;
}

div#detailed svg{
  font-size: calc(40px + 1vmin);
}

div#detailed img {
  height: calc(40px + 1vmin);
}

div#detailed h3{
  font-size: calc(10px + 1vmin);
  margin: 0;

}

.skill{

  margin: 10px;
  padding: 10px;
  border: solid 1px white ;
  border-radius: 20px;
  border-bottom-right-radius: 0px;

  width: calc(6vw + 6vh);

  font-size: calc(8px + 1vmin);
}



@media screen and (max-width: 700px) {



  div#detailed div{
    margin: 5px;
  }

}
/*  Projects Cards*/

.project-card {
  margin: 5px;

  border: solid 1px white ;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  width: calc(350px + 1vmin);
  height:35vh;

  position: relative;

}

.project-title {
  height: 15%;
}

.project-image {
  height: 85%;
}

.project-image > img {
  height: 100%;
  width: 100%;
  border-radius: 0px 0px 20px 0px;
}

.project-card h3{
  margin: 2px;
  padding: 5px;
}

.project-card p{
  margin: 0;
  padding: 5px;
}

.project-card:hover {
  background-color: white;
  color:black;
}

.project-front {
  height: 100%;
}

.project-back > p{
  font-size: calc(30px + 1vmin);
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.project-link {
  position: absolute;
  bottom: 0;
}

.project-link {
  width: 100%;
  padding-bottom: 5px;
}

.project-link > a {
  padding: 5px;
  margin: 0 10px;
  border: solid 1px rgb(152, 152, 152);
  border-radius: 10px;
  color: white;
  text-decoration: none;
}

.project-link > a:hover {
  background-color:rgb(108, 208, 202);
}

.project-link a {
  text-decoration: none;
  color: rgb(152, 152, 152);
}

/*  Projects */
.projects {

}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

/* Contact */

.contact {

}

.contact-link {
  background-color: rgb(158, 236, 241);
  color: rgb(124, 124, 124);
  font-size: 5vh;
  padding: 5px;
  margin: 10px;
  border-radius: 10px;

  display:block;
  /* display:inline-block; */

}

.contact-link:hover{
  color: rgb(44, 159, 215);
  background-color: rgb(0, 0, 0);
  animation: rotate 2s ;
  -webkit-animation: rotate 1s ;
}


@keyframes rotate {
  0%   {}
  100% {transform: rotate(-360deg);}
}
@-webkit-keyframes rotate {
  0%   {}
  100% {-webkit-transform: rotate(-360deg);}
}

.button-container {
  display: flex;
  bottom:0;
}

.button-container .arrow  {
  box-shadow:none;
}

.go-up-button {
  height: 20px;
  width: 20px;
  margin: 50px;
  padding: 20px;
  animation: bounce 0.75s ease-in-out infinite;
   -webkit-animation: bounce 0.75s ease-in-out infinite;
}
